import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "../Popover";
import { useCallback, useId, useState } from "react";
import { useSubmit } from "@remix-run/react";
import { toast } from "react-toastify";
import { cache } from "~/utils/cache";
import { useGetRedirectTo, writeLocalStorageAccounts } from "~/routes/login";
import { isSSR } from "~/utils/ssr";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { loadHivePrivateKey } from "~/utils/hive";

export default function LeoAuth() {
  const submit = useSubmit();
  const redirectTo = useGetRedirectTo();
  const [leoAuth, setLeoAuth] = useState({
    username: "",
    posting_key: ""
  });
  const [loading, setLoading] = useState(false);

  if (!isSSR()) window.Buffer = window.Buffer || require("buffer").Buffer;
  const handleLeoAuthLogin = useCallback(async () => {
    if (typeof window === "undefined" || loading) return;
    setLoading(true);
    const dhive = (window as any).dhive;
    const loginString = Buffer.from(`{"login":"${leoAuth.username}"}`);
    const PrivateKey = await loadHivePrivateKey();
    let privateKey;

    try {
      privateKey = PrivateKey.fromString(leoAuth.posting_key);
    } catch (e: any) {
      setLoading(false);
      if (e.name == "AssertionError") {
        return toast(
          `Private Key is Invalid! Please ensure you use the correct "Posting Key" and "Username"`,
          {
            type: "error",
            theme: "light",
            autoClose: 3_000
          }
        );
      }
      return toast(e.message, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }
    let publicKey;
    try {
      publicKey = privateKey.createPublic().toString();
    } catch {
      setLoading(false);
      return toast(`Posting key is malformed or wrong!`, {
        type: "error",
        theme: "light",
        autoClose: 3_000
      });
    }

    const account = await cache.getAccount(leoAuth.username);
    if (account.posting.key_auths[0][0] !== publicKey) {
      setLoading(false);
      return toast(
        `Posting key doesn't match the ${leoAuth.username}'s posting key!`,
        {
          type: "error",
          theme: "light",
          autoClose: 3_000
        }
      );
    }

    window?.crypto?.subtle
      ?.digest("SHA-256", loginString)
      .then(digestedMessage => {
        const signature = privateKey.sign(Buffer.from(digestedMessage));
        console.log("signature", signature.toString());
        window.localStorage.setItem("activeAccount", signature.toString());
        writeLocalStorageAccounts(leoAuth.username);
      });

    submit(
      {
        message: JSON.stringify({
          posting_key: leoAuth.posting_key
        }),
        accountName: leoAuth.username,
        type: "leolock",
        redirectTo
      },
      { method: "post", action: "/login" }
    );

    setLoading(false);
  }, [leoAuth, redirectTo, loading]);

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleLeoAuthLogin();
      }}
      className="flex flex-col gap-y-4"
    >
      <LeoAuthGroup
        title="Username"
        placeholder="Enter your username"
        type="text"
        value={leoAuth.username}
        onChange={(value: string) =>
          setLeoAuth(current => ({ ...current, username: value }))
        }
      />
      <LeoAuthGroup
        title="Posting Key"
        placeholder="Enter your private posting key"
        type="password"
        value={leoAuth.posting_key}
        onChange={(value: string) =>
          setLeoAuth(current => ({ ...current, posting_key: value }))
        }
      />

      <small
        id="private-key-tooltip"
        className="flex items-center gap-x-1 text-xs font-medium text-pri/40 dark:text-pri-d/40 -mt-3 pl-px"
      >
        We are not storing your keys.
        <FontAwesomeIcon icon={faCircleQuestion} size="sm" fixedWidth />
      </small>

      <Popover
        anchorId="private-key-tooltip"
        content="LeoAuth is a feature developed by the InLeo community, designed to securely store users' private keys in their browser and enable them to sign and broadcast transactions using dhive."
        className="max-w-[360px]"
      />

      <button
        type="submit"
        className="flex justify-center items-center min-w-[138px] sm:min-w-[185px] h-[30px] sm:h-[40px] py-2.5 px-6 mx-auto rounded-full text-sm font-medium bg-pri-d dark:bg-pri text-pri-d dark:text-pri hover:opacity-90 transition-opacity duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
        disabled={loading}
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
        ) : (
          "Sign in with LeoAuth"
        )}
      </button>
    </form>
  );
}

interface LeoAuthGroupProps {
  title: string;
  placeholder: string;
  type: "text" | "password";
  value: string;
  onChange: (value: string) => void;
}

function LeoAuthGroup({
  title,
  placeholder,
  type,
  value,
  onChange
}: LeoAuthGroupProps) {
  const id = useId();

  return (
    <div className="flex flex-col gap-y-1">
      <label
        htmlFor={id}
        className="font-medium text-sm text-pri dark:text-pri-d pl-px"
      >
        {title}
      </label>
      <input
        id={id}
        type={type}
        className="w-full py-2.5 px-3 rounded-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d text-sm font-medium outline-2 outline-offset-1 outline-transparent focus:outline-pri-d dark:focus:outline-pri placeholder:text-pri/40 dark:placeholder:text-pri-d/40 transition-all duration-150"
        placeholder={placeholder}
        value={value}
        onChange={e =>
          onChange(
            type === "text" ? e.target.value?.toLowerCase() : e.target.value
          )
        }
      />
    </div>
  );
}
