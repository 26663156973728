export function HiveSigner() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 250 50"
      version="1.1"
    >
      <defs id="defs2" />
      <g id="layer1" transform="translate(0,-247)">
        <g id="g995" transform="translate(2.381251)">
          <g id="g1043" transform="translate(1.8520845,-1.8649999e-6)">
            <g
              id="g637"
              fill="none"
              fillRule="evenodd"
              stroke="none"
              strokeWidth={1}
              transform="matrix(0.32014583,0,0,0.32014583,16.879399,251.03045)"
            >
              <g id="g635" fill="#e31337" fillOpacity={1}>
                <path
                  d="M 98.541667,69.923306 V 45.887017 H 0 V 0 H 110 V 22.943509 H 98.541667 V 11.471754 H 11.458333 V 34.415263 H 110 v 37.340478 c -0.18517,18.239539 -10.382232,33.200889 -27.247234,45.104989 -5.956567,4.20442 -12.346375,7.7265 -18.75154,10.59653 -2.257779,1.01167 -4.362872,1.87137 -6.261489,2.58208 -1.170273,0.43806 -2.105573,0.76041 -2.522569,0.89083 L 54.99392,131 54.770964,130.9292 c -0.467103,-0.14823 -1.351404,-0.45331 -2.518442,-0.89038 -1.898774,-0.71112 -4.002941,-1.57072 -6.259897,-2.58226 C 39.58819,124.58617 33.199766,121.06429 27.244225,116.8601 10.54075,105.06867 0.37991151,90.277944 0.0104348,72.272052 H 0 v -14.91328 h 11.458333 v 12.564534 c 0,13.829856 7.221147,25.398143 19.608216,34.855994 4.574183,3.49252 9.611145,6.50237 14.833332,9.04511 3.4621,1.68573 6.579291,2.96581 9.101659,3.85697 2.519577,-0.88982 5.638459,-2.17053 9.10069,-3.85634 5.221824,-2.5426 10.258741,-5.55255 14.832882,-9.04517 12.38688,-9.458104 19.606555,-21.026716 19.606555,-34.856564 z"
                  id="path633"
                />
              </g>
            </g>
            <g
              aria-label="hivesigner"
              id="text876"
              transform="translate(0,-1.5e-5)"
              fill="currentColor"
            >
              <path
                d="m 71.710308,280.6131 v -23.50175 h 4.326351 v 8.85871 q 0.74483,-0.8716 1.901693,-1.29949 1.17271,-0.44372 2.408811,-0.44372 1.965082,0 3.327962,0.87161 1.36288,0.85576 1.98093,2.25033 0.633897,1.39458 0.633897,3.20118 v 10.06313 h -4.310503 v -9.3183 q 0,-1.42627 -0.74483,-2.29787 -0.728982,-0.87161 -2.218641,-0.87161 -1.331185,0 -2.123557,0.87161 -0.776525,0.8716 -0.855762,2.23448 v 9.38169 z"
                id="path960"
              />
              <path
                d="m 90.156727,259.42507 q 0,-1.10932 0.776525,-1.90169 0.792372,-0.79237 1.885845,-0.79237 1.109321,0 1.885845,0.79237 0.776525,0.77652 0.776525,1.90169 0,1.07763 -0.776525,1.85415 -0.776524,0.77653 -1.885845,0.77653 -1.093473,0 -1.885845,-0.77653 -0.776525,-0.77652 -0.776525,-1.85415 z m 0.507118,21.18803 v -15.97422 h 4.326351 v 15.97422 z"
                id="path962"
              />
              <path
                d="m 97.620871,264.63888 h 4.738389 l 3.96186,10.71287 3.80338,-10.71287 h 4.54822 l -6.19635,15.97422 h -4.32635 z"
                id="path964"
              />
              <path
                d="m 115.59186,272.5626 q 0,-1.85415 0.64975,-3.45474 0.64974,-1.60059 1.72737,-2.66237 1.09347,-1.07763 2.5039,-1.67983 1.42627,-0.6022 2.96347,-0.6022 3.69245,0 5.78431,2.21864 2.10771,2.20279 2.10771,6.05372 0,0.26941 -0.0159,0.58635 -0.0158,0.31695 -0.0317,0.52297 -0.0159,0.20602 -0.0159,0.22186 h -11.426 q 0.0634,1.5689 1.2361,2.59898 1.17271,1.03009 2.78915,1.03009 2.7416,0 3.62906,-2.42466 l 3.61322,1.06178 q -0.64975,2.20279 -2.55144,3.64491 -1.90169,1.42627 -4.72254,1.42627 -1.64813,0 -3.13779,-0.58636 -1.48966,-0.58635 -2.63067,-1.64813 -1.12517,-1.07763 -1.80661,-2.70991 -0.6656,-1.63229 -0.6656,-3.59737 z m 4.3422,-1.74322 h 7.1472 q -0.0475,-1.31534 -0.95085,-2.23449 -0.9033,-0.91915 -2.63067,-0.91915 -1.5689,0 -2.53559,0.96669 -0.95085,0.9667 -1.03009,2.18695 z"
                id="path966"
              />
              <path
                d="m 133.35684,276.15997 3.7083,-0.80822 q 0.0792,1.03008 0.82407,1.74322 0.76068,0.69728 2.09186,0.69728 1.01424,0 1.55305,-0.45957 0.55466,-0.45958 0.55466,-1.14102 0,-1.2044 -1.71152,-1.55305 l -2.10771,-0.49127 q -2.23449,-0.49127 -3.35966,-1.75906 -1.12517,-1.2678 -1.12517,-3.01102 0,-2.1711 1.71153,-3.69245 1.71152,-1.52135 4.26296,-1.52135 1.29949,0 2.36127,0.31695 1.07762,0.31694 1.75906,0.80821 0.68144,0.47543 1.15687,1.10933 0.47542,0.63389 0.69728,1.22025 0.22187,0.57051 0.28526,1.14101 l -3.59737,0.82407 q -0.11093,-0.85576 -0.76068,-1.48966 -0.6339,-0.64974 -1.87,-0.64974 -0.85576,0 -1.45796,0.45957 -0.58636,0.44373 -0.58636,1.12517 0,0.57051 0.39619,0.935 0.39619,0.36449 1.06178,0.49127 l 2.26618,0.49127 q 2.29788,0.47542 3.51813,1.79076 1.22026,1.29949 1.22026,3.1061 0,0.99839 -0.39619,1.93339 -0.38034,0.91915 -1.12517,1.67983 -0.74483,0.74482 -1.96508,1.18855 -1.20441,0.45958 -2.70991,0.45958 -1.37873,0 -2.51975,-0.34864 -1.12516,-0.3328 -1.85415,-0.85577 -0.71313,-0.53881 -1.2361,-1.22025 -0.50711,-0.68144 -0.74483,-1.29949 -0.23771,-0.6339 -0.3011,-1.22025 z"
                id="path968"
              />
              <path
                d="m 149.17259,259.42507 q 0,-1.10932 0.77652,-1.90169 0.79238,-0.79237 1.88585,-0.79237 1.10932,0 1.88584,0.79237 0.77653,0.77652 0.77653,1.90169 0,1.07763 -0.77653,1.85415 -0.77652,0.77653 -1.88584,0.77653 -1.09347,0 -1.88585,-0.77653 -0.77652,-0.77652 -0.77652,-1.85415 z m 0.50712,21.18803 v -15.97422 h 4.32635 v 15.97422 z"
                id="path970"
              />
              <path
                d="m 157.4925,281.48471 3.88262,-1.03009 q 0.22186,1.34704 1.22025,2.1711 1.01424,0.83992 2.51974,0.83992 4.24712,0 4.24712,-4.43729 v -1.17271 q -0.53881,0.87161 -1.67983,1.45797 -1.14102,0.58635 -2.75745,0.58635 -3.20119,0 -5.32474,-2.20279 -2.10771,-2.20279 -2.10771,-5.56245 0,-2.10771 0.935,-3.88262 0.93499,-1.77492 2.64652,-2.8367 1.71152,-1.06177 3.85093,-1.06177 1.75906,0 2.90008,0.63389 1.15686,0.61805 1.63228,1.5372 v -1.88584 h 4.16788 v 14.24685 q 0,1.34703 -0.3011,2.55143 -0.28526,1.20441 -0.935,2.28204 -0.6339,1.07762 -1.60059,1.85415 -0.95085,0.79237 -2.37712,1.2361 -1.42627,0.45957 -3.16949,0.45957 -3.15364,0 -5.27719,-1.69567 -2.10771,-1.67983 -2.4722,-4.08864 z m 4.34219,-9.34999 q 0,1.85415 1.06178,2.96347 1.07763,1.09347 2.77331,1.09347 1.66398,0 2.70991,-1.10932 1.04593,-1.10932 1.04593,-2.94762 0,-1.80661 -1.07763,-2.90008 -1.07762,-1.10932 -2.67821,-1.10932 -1.64814,0 -2.74161,1.10932 -1.09348,1.09347 -1.09348,2.90008 z"
                id="path972"
              />
              <path
                d="m 178.01493,280.6131 v -15.97422 h 4.19957 v 1.98093 q 0.69729,-1.17271 1.94923,-1.77491 1.2678,-0.61805 2.64653,-0.61805 2.83669,0 4.3105,1.77491 1.47381,1.75907 1.47381,4.54821 v 10.06313 h -4.3105 v -9.3183 q 0,-1.42627 -0.74483,-2.29787 -0.72898,-0.87161 -2.21864,-0.87161 -1.37873,0 -2.18695,0.935 -0.79237,0.93499 -0.79237,2.36126 v 9.19152 z"
                id="path974"
              />
              <path
                d="m 195.77991,272.5626 q 0,-1.85415 0.64974,-3.45474 0.64975,-1.60059 1.72737,-2.66237 1.09348,-1.07763 2.5039,-1.67983 1.42627,-0.6022 2.96347,-0.6022 3.69245,0 5.78431,2.21864 2.10771,2.20279 2.10771,6.05372 0,0.26941 -0.0158,0.58635 -0.0159,0.31695 -0.0317,0.52297 -0.0159,0.20602 -0.0159,0.22186 h -11.426 q 0.0634,1.5689 1.2361,2.59898 1.17271,1.03009 2.78915,1.03009 2.74161,0 3.62906,-2.42466 l 3.61322,1.06178 q -0.64975,2.20279 -2.55144,3.64491 -1.90169,1.42627 -4.72254,1.42627 -1.64813,0 -3.13779,-0.58636 -1.48966,-0.58635 -2.63067,-1.64813 -1.12517,-1.07763 -1.80661,-2.70991 -0.66559,-1.63229 -0.66559,-3.59737 z m 4.34219,-1.74322 h 7.1472 q -0.0475,-1.31534 -0.95085,-2.23449 -0.9033,-0.91915 -2.63067,-0.91915 -1.5689,0 -2.53559,0.96669 -0.95085,0.9667 -1.03009,2.18695 z"
                id="path976"
              />
              <path
                d="m 214.90776,280.6131 v -15.97422 h 4.19957 v 2.37712 q 0.34864,-0.74483 0.9033,-1.2678 0.57051,-0.53881 1.22026,-0.77652 0.66559,-0.25356 1.2361,-0.34865 0.58635,-0.11093 1.17271,-0.11093 0.38033,0 1.01423,0.0634 v 4.35805 q -0.64974,-0.12678 -1.29949,-0.12678 -0.87161,0 -1.58474,0.23771 -0.69729,0.22186 -1.29949,0.72898 -0.58636,0.49127 -0.91915,1.39457 -0.31695,0.88746 -0.31695,2.13941 v 7.30567 z"
                id="path978"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function HiveKeychain() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="527.387"
      height="112.71"
      viewBox="0 0 527.387 112.71"
    >
      <defs>
        <style>{`.a{fill:currentColor;}.b{fill:#e31337;}`}</style>
      </defs>
      <path
        className="a"
        d="M155.636,34.449a29.9,29.9,0,0,0-2.364-1.889,31.509,31.509,0,0,0-8.415-4.381l-.1-.034A32.029,32.029,0,0,0,105.715,72.6l-1.85,2.1L78.017,104.061A12.22,12.22,0,0,0,75.9,116.841a12.1,12.1,0,0,0,3.213,4.47,12.226,12.226,0,0,0,17.26-1.118c.061-.067.129-.14.184-.212h0a7.426,7.426,0,0,0,10.008-10.946l-.218-.162c-.061-.045-.117-.1-.168-.14s-.24-.229-.352-.346a4.588,4.588,0,0,1,6.42-6.543l.156.145.034-.034a7.426,7.426,0,0,0,9.812-11.136l1.833-2.084a32.012,32.012,0,0,0,31.548-54.318Zm-6.979,26.095a11.824,11.824,0,0,1-3.353,2.671,12.019,12.019,0,1,1,3.353-2.671Z"
        transform="translate(-33.075 -11.674)"
      />
      <path
        className="b"
        d="M48.474,63a7.415,7.415,0,0,0,4.778-9.175l2.626-.877a32.085,32.085,0,0,0,13.092,9.08,32.023,32.023,0,0,1,34.543-51.877A32.023,32.023,0,0,0,48.195,29.709l-2.654.877L8.382,42.93a12.228,12.228,0,0,0,7.7,23.211l.268-.089h0A7.426,7.426,0,0,0,30.51,61.643c-.039-.078-.073-.168-.106-.251a1.117,1.117,0,0,1-.073-.2c-.056-.162-.095-.324-.134-.481A4.593,4.593,0,0,1,39.042,58.3a.968.968,0,0,0,.061.207h.045A7.426,7.426,0,0,0,48.474,63Z"
        transform="translate(-0.001 -0.011)"
      />
      <path
        className="a"
        d="M282.755,58.951l26.821-30.487a1.3,1.3,0,0,0-.821-2.274h-8.527a6.65,6.65,0,0,0-4.822,2.364L275.653,53.218h-1.816L277.3,29.1a2.542,2.542,0,0,0-2.548-2.911h-6.739a3.353,3.353,0,0,0-3.274,2.911L256.543,86.99A2.436,2.436,0,0,0,259,89.9h6.823a3.409,3.409,0,0,0,3.258-2.911l3-21.3h1.637l13.109,21.937A5.029,5.029,0,0,0,291.1,89.9h9.466a1.5,1.5,0,0,0,1.369-2.185Z"
        transform="translate(-113.185 -11.562)"
      />
      <path
        className="a"
        d="M392.089,26.189H359.87A3.353,3.353,0,0,0,356.6,29.1L348.4,86.989a2.438,2.438,0,0,0,2.459,2.911H383.83a3.308,3.308,0,0,0,3.185-2.911l.637-4.554a2.358,2.358,0,0,0-2.459-2.794H362.138l2.185-15.567h18.11a3.409,3.409,0,0,0,3.274-2.911l.637-4.47a2.433,2.433,0,0,0-2.459-2.911h-18.1l2.369-17.2h22.479a3.481,3.481,0,0,0,3.274-2.794l.637-4.683a2.436,2.436,0,0,0-2.458-2.911Z"
        transform="translate(-153.717 -11.561)"
      />
      <path
        className="a"
        d="M486.154,26.189h-7.376a5.141,5.141,0,0,0-4.275,2.637L459.417,56.854l-7.393-28.028a3.554,3.554,0,0,0-3.548-2.637h-7.739a2.02,2.02,0,0,0-2,2.548L451.3,68.052l-2.732,18.937a2.5,2.5,0,0,0,2.565,2.906h6.918a3.313,3.313,0,0,0,3.185-2.911l2.732-19.116L487.35,28.753A1.542,1.542,0,0,0,486.154,26.189Z"
        transform="translate(-193.536 -11.56)"
      />
      <path
        className="a"
        d="M657.26,86.99,665.452,29.1a2.481,2.481,0,0,0-2.458-2.911h-7.007A3.308,3.308,0,0,0,652.8,29.1l-3.459,24.754H630.048L633.507,29.1a2.38,2.38,0,0,0-2.369-2.911H624.22a3.353,3.353,0,0,0-3.274,2.911L612.754,86.99a2.438,2.438,0,0,0,2.458,2.911h7.007A3.381,3.381,0,0,0,625.4,86.99l3.185-22.753h19.2L644.609,86.99a2.481,2.481,0,0,0,2.459,2.911h6.918A3.353,3.353,0,0,0,657.26,86.99Z"
        transform="translate(-270.355 -11.561)"
      />
      <path
        className="a"
        d="M754.984,87.163,743.518,28.917a3.632,3.632,0,0,0-3.554-2.727h-6.1a5.113,5.113,0,0,0-4.275,2.727L701.817,87.163a1.784,1.784,0,0,0,1.548,2.732h7.459a4.532,4.532,0,0,0,4.006-2.794l5.459-12.561h19.524l2,12.651a3.258,3.258,0,0,0,3.28,2.732h7.644a2.28,2.28,0,0,0,2.246-2.76ZM724.5,64.684,734.6,41.02l3.638,23.664Z"
        transform="translate(-309.558 -11.562)"
      />
      <path
        className="a"
        d="M825.462,26.19h-6.923a3.353,3.353,0,0,0-3.28,2.911L807.073,86.99a2.432,2.432,0,0,0,2.453,2.911h7a3.392,3.392,0,0,0,3.191-2.911L827.82,29.1a2.375,2.375,0,0,0-2.358-2.911Z"
        transform="translate(-356.093 -11.561)"
      />
      <path
        className="a"
        d="M898.594,26.19h-6.823a3.235,3.235,0,0,0-3.185,2.911l-5.029,37.862L869.471,28.738a4.163,4.163,0,0,0-3.822-2.548h-7.37A3.353,3.353,0,0,0,855,29.1L846.813,86.99a2.432,2.432,0,0,0,2.453,2.911h6.828A3.23,3.23,0,0,0,859.2,86.99l5.1-37.678,13.835,38.041a4.158,4.158,0,0,0,3.822,2.548H889.6a3.475,3.475,0,0,0,3.274-2.911L900.964,29.1a2.42,2.42,0,0,0-2.369-2.911Z"
        transform="translate(-373.627 -11.562)"
      />
      <path
        className="a"
        d="M566.317,32.711a31.85,31.85,0,1,0-7.532,54.5,2.045,2.045,0,0,0,.933-.777A6.386,6.386,0,0,0,560.78,82.9l1.118-8.8a1.928,1.928,0,0,0-3.157-1.721,19,19,0,1,1,3.319-25.67,1.939,1.939,0,0,0,3.515-.849l1.475-11.326a2,2,0,0,0-.732-1.822Z"
        transform="translate(-227.142 -11.439)"
      />
      <circle
        className="a"
        cx="12.014"
        cy="12.014"
        r="12.014"
        transform="translate(307.777 34.582)"
      />
      <path
        className="a"
        d="M640.81,159.12v23.468h2.682v-9.823h10.393v-2.548H643.492v-8.549h15.255V159.12H640.81Z"
        transform="translate(-282.743 -70.214)"
      />
      <path
        className="a"
        d="M689.361,158.52A12.072,12.072,0,1,0,701.7,170.59,11.954,11.954,0,0,0,689.361,158.52Zm0,21.557a9.5,9.5,0,1,1,9.656-9.5A9.248,9.248,0,0,1,689.361,180.077Z"
        transform="translate(-298.707 -69.949)"
      />
      <path
        className="a"
        d="M751.3,166.161c0-4.191-3.319-7.041-7.644-7.041H731.42v23.468H734.1v-9.454h9.253l6.4,9.454h2.883v-.2l-6.51-9.589A6.785,6.785,0,0,0,751.3,166.161Zm-17.2,4.47v-8.94h9.454c2.883,0,5.063,1.71,5.063,4.47s-2.179,4.47-5.062,4.47Z"
        transform="translate(-322.722 -70.214)"
      />
      <path
        className="a"
        d="M813.979,169.245H798.892V159.12H796.21v23.468h2.682v-10.8h15.087v10.8h2.682V159.12h-2.682Z"
        transform="translate(-351.31 -70.214)"
      />
      <rect
        className="a"
        width="2.682"
        height="23.468"
        transform="translate(472.493 88.906)"
      />
      <path
        className="a"
        d="M870.118,179.3l-9.086-20.183H858.25v.235l10.594,23.234h2.515l10.628-23.234v-.235H879.2Z"
        transform="translate(-378.683 -70.214)"
      />
      <path
        className="a"
        d="M911.212,172.095H921.1v-2.548h-9.891v-7.879h15.355V159.12H908.53v23.468h18.2v-2.582H911.212Z"
        transform="translate(-400.868 -70.214)"
      />
      <rect
        className="b"
        width="191.101"
        height="2.794"
        transform="translate(143.498 99.958)"
      />
    </svg>
  );
}

export function HiveKeychainKeys() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0.11 0 133.32 112.68"
    >
      <defs>
        <style>{`.a{fill:currentColor;}.b{fill:#e31337;}`}</style>
      </defs>
      <path
        xmlns="http://www.w3.org/2000/svg"
        className="a"
        d="M155.636,34.449a29.9,29.9,0,0,0-2.364-1.889,31.509,31.509,0,0,0-8.415-4.381l-.1-.034A32.029,32.029,0,0,0,105.715,72.6l-1.85,2.1L78.017,104.061A12.22,12.22,0,0,0,75.9,116.841a12.1,12.1,0,0,0,3.213,4.47,12.226,12.226,0,0,0,17.26-1.118c.061-.067.129-.14.184-.212h0a7.426,7.426,0,0,0,10.008-10.946l-.218-.162c-.061-.045-.117-.1-.168-.14s-.24-.229-.352-.346a4.588,4.588,0,0,1,6.42-6.543l.156.145.034-.034a7.426,7.426,0,0,0,9.812-11.136l1.833-2.084a32.012,32.012,0,0,0,31.548-54.318Zm-6.979,26.095a11.824,11.824,0,0,1-3.353,2.671,12.019,12.019,0,1,1,3.353-2.671Z"
        transform="translate(-33.075 -11.674)"
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        className="b"
        d="M48.474,63a7.415,7.415,0,0,0,4.778-9.175l2.626-.877a32.085,32.085,0,0,0,13.092,9.08,32.023,32.023,0,0,1,34.543-51.877A32.023,32.023,0,0,0,48.195,29.709l-2.654.877L8.382,42.93a12.228,12.228,0,0,0,7.7,23.211l.268-.089h0A7.426,7.426,0,0,0,30.51,61.643c-.039-.078-.073-.168-.106-.251a1.117,1.117,0,0,1-.073-.2c-.056-.162-.095-.324-.134-.481A4.593,4.593,0,0,1,39.042,58.3a.968.968,0,0,0,.061.207h.045A7.426,7.426,0,0,0,48.474,63Z"
        transform="translate(-0.001 -0.011)"
      />
    </svg>
  );
}

